import axios from "axios";
import CheckHttp from "@/asyncs/MyHttpCheck.js";
import {
    Notify
} from "vant";
import Env from "@/logics/Envs.js";

export default async function (schoolCode, teacherId, showHidden) {
    try {
        const params = { schoolCode, teacherId, showHidden };
        const res = await axios.get(Env.freePath + "listClassBasics", { params });
        if (res.data.error) {
            Notify({
                type: "danger",
                message: res.data.error,
                duration: 5000
            });
            return null;
        }
        return res.data.list;
    } catch (err) {
        CheckHttp(err);
        return null;
    }
}